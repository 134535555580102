import { get, getAndHandleMessage, postAndHandleMessage } from './apiRequest';
import { getImage } from './graphRequest';
import { generateGUID } from '../helpers/content';
import { DesignationGroupId } from '../autogenerated/api.generated.clients';

export async function loadUser() {
  let userName = '';
  let userId = null;
  let fullName = '';
  let emailAddress;
  let userTypeId;
  let azureObjectId;
  let designationGroupIds;
  let viewRestricted;
  let archived;
  let showDashboard;
  let serviceCoordinatorIds = [];
  let assignedToIds = [];
  let hrLiaisonIds = [];
  let implementationManagerIds = [];
  let implementationCoordinatorIds = [];

  try {
    const data = await get('/user/current', null, false);
    userTypeId = data.userTypeId;
    // userTypeId = null;
    // If usertype ends up being null or invalid, log the user out
    if (data === null) {
      // Log the user out or take them to a page with a message that they need to be granted access
      throw 'Unable to retrieve user data';
    }
    else if (!userTypeId || userTypeId === null || userTypeId === 'Invalid') {
      // Log the user out or take them to a page with a message that they need to be granted access
      throw 'Unable to retrieve user data';
    } else {
      userName = data.username;
      userId = data.id;
      fullName = data.name;
      emailAddress = data.email;
      azureObjectId = data.azureObjectId;
      designationGroupIds = data.designationGroupIds;
      viewRestricted = data.viewRestricted;
      archived = data.archived;
      showDashboard = data.showDashboard;
      serviceCoordinatorIds = data.serviceCoordinatorIds;
      assignedToIds = data.assignedToIds;
      hrLiaisonIds = data.hrLiaisonIds;
      implementationManagerIds = data.implementationManagerIds;
      implementationCoordinatorIds = data.implementationCoordinatorIds;
    }
  } catch (error) {
    throw error;
  }

  return {
    userName,
    userId,
    fullName,
    emailAddress,
    userTypeId,
    azureObjectId,
    designationGroupIds,
    viewRestricted,
    archived,
    showDashboard,
    serviceCoordinatorIds,
    assignedToIds,
    hrLiaisonIds,
    implementationManagerIds,   
    implementationCoordinatorIds,
  };
}

export async function clearUser() {
  const userName = '';
  const userId = null;
  const fullName = '';
  const emailAddress = '';
  const userTypeId = null;
  const azureObjectId = '';
  const designationGroupIds = [];
  const viewRestricted = null;
  const archived = null;
  const showDashboard = null;
  const serviceCoordinatorIds = [];
  const assignedToIds = [];
  const hrLiaisonIds = [];
  const implementationManagerIds = [];
  const implementationCoordinatorIds = [];

  return {
    userName,
    userId,
    fullName,
    emailAddress,
    userTypeId,
    azureObjectId,
    designationGroupIds,
    viewRestricted,
    archived,
    showDashboard,
    serviceCoordinatorIds,
    assignedToIds,
    hrLiaisonIds,
    implementationManagerIds,
    implementationCoordinatorIds,
  };
}

export async function getUserPhoto() {
  let userPhoto = null;
  userPhoto = await getImage();
  return {
    userPhoto,
  };
}
export async function getAllActiveCompassUsers() {
    return await getAndHandleMessage('/user/activeList');
}

export async function getUserFilterList() {
    return await getAndHandleMessage('/user/filterList');
}

export async function createCompassUser(payload) {
  if (!payload.isSalesRep) {
    return await postAndHandleMessage('/user/create', payload);
  } else {
    return await postAndHandleMessage('/salesrepresentative/create', payload);
  }
}

export async function getAllUsersAzure(){
    const response = await getAndHandleMessage('/entra/users');
    return response.data;
}
export async function getDashboardUsers() {
    return await getAndHandleMessage("/user/dashboardList")
        .then((usersResponse) => {
            return usersResponse?.data ?? [];
        });
}


export async function getAppealSpecialistUsers() {
  return await getAndHandleMessage("/user/specialists")
  .then((usersResponse) => {
      return usersResponse?.data ?? [];
  });
}

export async function getAppealAuditUsers() {
  return await getAndHandleMessage('/user/auditors')
  .then((usersResponse) => {
      return usersResponse?.data ?? [];
  });
}

export async function getNavigationLinks(userAccess) {
  const servicingSection = {
    'title': 'Servicing',
    'navItems': [
      {
        'title': 'Patients',
        'path': '/patients',
      },
    ],
  };

  const providersSection = {
    'title': 'Providers',
    'navItems': [
      {
        'title': 'Practitioner Search',
        'path': '/doctors',
      },
      {
        'title': 'Provider News',
        'path': '/news/all',
      },
    ],
  };

  if (userAccess.designationGroupIds.includes(DesignationGroupId.ProviderInfoView)) {
    providersSection.navItems.push(
      {
        'title': 'Facility Search',
        'path': '/facilities',
      },
    );
  }

  const teamSection = {
    'title': 'Team',
    'navItems': [
      {
        'title': 'Member Advocates',
        'path': '/memberadvocates',
      },
    ],
  };

  const miscSection = {
    'title': 'Misc',
    'navItems': [
      {
        'title': 'Calendar',
        'path': "/calendar",
      },
      {
        'title': 'Reminders',
        'path': "/reminders",
      },
      {
        'title': 'Call Guides',
        'path': '/callguides',
      },
      {
        'title': 'Reports',
        'path': '/reports',
      },
      {
        'title': 'Site Search',
        'path': '/search',
      },
    ],
  };

  if (userAccess.userTypeId === 'Administrator' || userAccess.userTypeId === 'Executive') {
    servicingSection.navItems.push(
      {
        'title': 'Clients',
        'path': '/clients/table',
      },
    );
  } else {
    servicingSection.navItems.push(
      {
        'title': 'Clients',
        'path': '/clients/list',
      },
    );
  }
  
  if (userAccess.designationGroupIds.includes(DesignationGroupId.MemberView)) {
    servicingSection.navItems.push(
      {
        'title': 'Members',
        'path': '/members',
      },
    );
  }

  if (userAccess.designationGroupIds.includes(DesignationGroupId.AppealsOne) ||
    userAccess.designationGroupIds.includes(DesignationGroupId.AppealsTwo) ||
    userAccess.designationGroupIds.includes(DesignationGroupId.Legal) ||
    userAccess.designationGroupIds.includes(DesignationGroupId.Auditor)) {
    teamSection.navItems.push(
      {
        'title': 'Appeals',
        'path': '/appeals',
      });
  }

  if (userAccess.userTypeId === 'ServiceCoordinator' ||
    userAccess.userTypeId === 'AccountManager' ||
    userAccess.userTypeId === 'Administrator') {
    teamSection.navItems.push(
      {
        'title': 'Account Managers',
        'path': '/accountmanagers',
      });
  }

  if (userAccess.designationGroupIds.includes(DesignationGroupId.ProviderRelations)) {
    teamSection.navItems.push(
      {
        'title': 'Provider Relations',
        'path': '/providerrelations',
      },
    );
  }

  if (userAccess.designationGroupIds.includes(DesignationGroupId.NominationDashboardView)) {
    servicingSection.navItems.push(
      {
        'title': 'Nominations',
        'path': '/nominations',
      },
    );
  }

  if (userAccess.designationGroupIds.includes(DesignationGroupId.NominationReconcileDashboardView)) {
    servicingSection.navItems.push(
      {
        'title': 'Nomination Reconcile',
        'path': '/reconcile',
      },
    );
  }

  servicingSection.navItems.sort((a, b) => a.title.localeCompare(b.title));
  providersSection.navItems.sort((a, b) => a.title.localeCompare(b.title));
  teamSection.navItems.sort((a, b) => a.title.localeCompare(b.title));
  miscSection.navItems.sort((a, b) => a.title.localeCompare(b.title));

  const links = [
    servicingSection,
    providersSection,
    teamSection,
    miscSection,
  ];

  return {
    links,
  };
}

export async function createNewCorrelationId() {
  try {
    const correlationId = generateGUID();
    return { correlationId };
  } catch (error) {
    throw error;
  }
}

export async function getAllMemberAdvocateUsers() {
  return await getAndHandleMessage('/user/activeMemberAdvocates');
}

export async function getUsers(params) {
  return await postAndHandleMessage('/user/users', params.userIds);
}

export default {
  loadUser,
  clearUser,
  getDashboardUsers,
  getUserPhoto,
  getNavigationLinks,
  getAllUsersAzure,
  getAllActiveCompassUsers,
  createNewCorrelationId,
  createCompassUser,
  getAppealSpecialistUsers,
  getAppealAuditUsers,
  getAllMemberAdvocateUsers,
  getUsers,
};
