import { getNoAuth } from './apiRequest';
import {logEvent} from "@/helpers/logging.js";

export async function getApplicationSettings() {
  let data = [];
  let version = '';
  try {
    data = await getNoAuth('/UI/settings');
    version = await getNoAuth('/version');
  } catch (error) {
      const logObject = {
          type: 'error',
          message: `Error fetching application settings: ${error}`,
          fileName: 'applicationSettings.js',
          functionName: 'getApplicationSettings',
      };
      logEvent(logObject);
  }

  return {
    data, 
      "appVersion" : version,
  };
}

export async function getApplicationVersion(currentCount) {
  let version = '';
  try {
    version = await getNoAuth('/version');
  } catch (error) {
    // Only log an error if we have tried to fetch the version more than 10 times
    if (currentCount >= 10) {
      const logObject = {
          type: 'error',
          message: `Error fetching version: ${error}`,
          fileName: 'applicationSettings.js',
          functionName: 'getApplicationVersion',
      };
      logEvent(logObject);
    }
  }
  return version;
}

export default {
    getApplicationSettings, 
    getApplicationVersion,
};
