import { createRouter, createWebHistory } from 'vue-router';
import { VueNavigationClient } from './helpers';
import { initializeUser, handleAuthorization, handleLogin } from '../helpers/auth';
import { logEvent }from '../helpers/logging';
import nominationsRoutes from './nominations';
import accountManagersRoutes from './accountManagers';
import memberAdvocatesRoutes from './memberAdvocates';
import clientsRoutes from './clients';
import membersRoutes from './members';
import patientsRoutes from './patients';
import adminRoutes from './admin';
import amSettingsRoutes from './amSettings';
import providerrelationsRoutes from './providerRelations';
import reportsRoutes from './reports';
import miscRoutes from './misc';
import reminderRoutes from './reminders';
import newsRoutes from './news';
import badRequestRoutes from './badRequest';
import facilitiesRoutes from './facilities';
import appealsRoutes from './appeals';
import practitionersRoutes from './practitioners';
import featureToggleRoutes from './toggles';
import { useAuthenticateStore } from '../store/pinia/authenticate';

const unmatched = '/:pathMatch(.*)*';

const routes = [
  {
    path: '/login',
    name: 'login',
    components: {
      unguardedContent: () => import('../views/Login.vue'),
    },
    meta: {
      title: 'Login',
      requiresAuth: false,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    components: {
      unguardedContent: () => import('../views/Logout.vue'),
    },
    meta: {
      title: 'Logout',
      requiresAuth: false,
    },
  },
  ...adminRoutes,
  ...amSettingsRoutes,
  ...nominationsRoutes,
  ...reminderRoutes,
  ...accountManagersRoutes,
  ...memberAdvocatesRoutes,
  ...clientsRoutes,
  ...membersRoutes,
  ...newsRoutes,
  ...patientsRoutes,
  ...providerrelationsRoutes,
  ...reportsRoutes,
  ...miscRoutes,
  ...facilitiesRoutes,
  ...appealsRoutes,
  ...practitionersRoutes,
  ...badRequestRoutes,
  ...featureToggleRoutes,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Hook MSAL into the router
const client = new VueNavigationClient(router);

// set up auth and guard routes
router.beforeEach(async (to, from, next) => {
  const simplifiedToPath = { path: to.path, name: to.name };
  const simplifiedFromPath = { path: from.path, name: from.name };

  logEvent({
    type: 'verbose',
    message: `Called: to, from: ${JSON.stringify(simplifiedToPath)} ${JSON.stringify(simplifiedFromPath)}`,
    functionName: 'beforeEach',
    fileName: 'router/index.js',
  });

  if (to.matched.some(record => record.path === unmatched)) {
    // 404, so don't complete any additional auth logic
    return next();
  }

  if (!to.meta.requiresAuth) {
    // if the route doesn't require auth, just go to the next route
    return next();
  }

  const authenticateStore = useAuthenticateStore();

  if (!authenticateStore.initialized) {
    try {
      await initializeUser(client);
    } catch {
      logEvent({
        type: 'error',
        message: 'Router beforeEach - Initialized Error',
        functionName: 'beforeEach',
        fileName: 'router/index.js',
      });
      return next(false);
    }
  }

  if (authenticateStore.account) {
    await handleAuthorization(to, next);
  } else {
    await handleLogin(next);
  }
});

export default router;
