import { logEvent }from './logging';
import { doesHaveAccessToPage } from '../auth/authorization';
import { useAuthenticateStore } from '../store/pinia/authenticate';
import { useUserStore } from '../store/pinia/user';
import { useMasqueradeStore } from '../store/pinia/masquerade';

export async function initializeUser(client) {
  const authenticateStore = useAuthenticateStore();

  logEvent({ type: 'verbose', message: 'Router beforeEach - Initialized Start',
    functionName: 'initializeUser', fileName: 'helpers/auth.js' });

  await authenticateStore.setAuthInProgress(true);
  await authenticateStore.initialize(client);

  logEvent({ type: 'verbose', message: 'Router beforeEach - Initialized End',
    functionName: 'initializeUser', fileName: 'helpers/auth.js' });
}

export async function handleTokenExpiration() {
  const authenticateStore = useAuthenticateStore();
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const tokenExpirationTimestamp = authenticateStore.account.idTokenClaims.exp;
  if (currentTimestamp > tokenExpirationTimestamp) {
    await authenticateStore.refreshToken();
    logEvent({ type: 'verbose', message: `Refreshed Token: ${JSON.stringify(authenticateStore.account)}`,
      functionName: 'handleTokenExpiration', fileName: 'helpers/auth.js' });
  }
}

export async function loadUserDetails() {
  const userStore = useUserStore();
  const masqueradeStore = useMasqueradeStore();

  if (!userStore.userDetails.userId || !userStore.userDetails.userName || !userStore.userDetails.fullName) {
    await userStore.loadUser();
    await masqueradeStore.loadUser();
  }
}

export async function checkAccess(to) {
  const userStore = useUserStore();

  const doesHaveAccess = await doesHaveAccessToPage(to.name, to.meta.accessObject);
  if (!doesHaveAccess) {
    logEvent({ type: 'verbose', message: `Blocked access to page: ${to.name} - ${userStore.userDetails.userAccess.userTypeId} is NOT in ${to.meta.accessObject}`,
      functionName: 'checkAccess', fileName: 'helpers/auth.js' });
    return false;
  }
  return true;
}

export async function handleAuthorization(to, next) {
  const authenticateStore = useAuthenticateStore();
  const userStore = useUserStore();

  try {
    logEvent({ type: 'verbose', message: `Authorized Start: ${JSON.stringify(authenticateStore.account)}`,
      functionName: 'handleAuthorization', fileName: 'helpers/auth.js' });

    await handleTokenExpiration();

    if (!userStore.userDetails.userPhoto) {
      userStore.getUserPhoto();
    }
    if (!userStore.correlationId) {
      userStore.createNewCorrelationId();
    }

    await loadUserDetails();

    await authenticateStore.setAuthInProgress(false);

    if (!(await checkAccess(to))) {
      return next({ path: '/unable-to-access', query: { pageToAccess: to.fullPath } });
    }

    logEvent({ type: 'verbose', message: `Authorized End: ${JSON.stringify(authenticateStore.account)}`,
      functionName: 'handleAuthorization', fileName: 'helpers/auth.js' });

    return next();
  } catch (err) {
    await authenticateStore.setAuthInProgress(false);
    logEvent({ type: 'error', message: `Authorized Error: ${err.message} for account ${JSON.stringify(authenticateStore.account)}`,
      functionName: 'handleAuthorization', fileName: 'helpers/auth.js' });
    return next({ path: '/access-issue' });
  }
}

export async function handleLogin(next) {
  const authenticateStore = useAuthenticateStore();
  const userStore = useUserStore();

  try {
    logEvent({ type: 'verbose', message: `Login Start: ${JSON.stringify(authenticateStore.account)}`,
      functionName: 'handleLogin', fileName: 'helpers/auth.js' });

    await userStore.createNewCorrelationId();
    await authenticateStore.login();
    return next();
  } catch (err) {
    logEvent({ type: 'error', message: `Login Error: ${err.message} for account ${JSON.stringify(authenticateStore.account)}`,
      functionName: 'handleLogin', fileName: 'helpers/auth.js' });
    await authenticateStore.setAuthInProgress(false);
    return next(false);
  }
}